import check from './check'
import login from './login'
// import logout from './logout'
import demo from './demo'
import forgot from './forgot'
import resetPassword from './resetPassword'

export default {
  check,
  login,
  forgot,
  resetPassword,
  demo
}
