import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'vi',
  fallbackLocale: 'en',
  messages: {
    vi: {
      langs: [
        {
          title: 'Tiếng Việt',
          img: 'icon-flag-vi',
          value: 'vi'
        },
        {
          title: 'English',
          img: 'icon-flag-en',
          value: 'en'
        }
      ],
      home: 'Trang chủ',
      supportChannel: 'Hỗ trợ dịch vụ',
      accountMenuTxt: 'Tài khoản',
      billingMenuTxt: 'Thanh toán',
      signOutMenuTxt: 'Đăng xuất',
      support: 'Hỗ trợ',
      create: 'Tạo mới',
      status: 'Trạng thái',
      name: 'Tên',
      email: 'Email',
      size: 'Dung lượng',
      type: 'Loại',
      action: 'Thao tác',
      config: 'Cấu hình',
      created: 'Ngày tạo',
      expired: 'Ngày hết hạn',
      version: 'Phiên bản',
      info: 'Thông tin',
      viewAll: 'Xem tất cả',
      no: 'Không',
      update: 'Cập nhật',
      confirm: 'Xác nhận',
      delete: 'Xóa',
      selectTime: 'Chọn thời gian',
      months: 'tháng',
      here: 'tại đây',
      currencyUnit: 'VNĐ',
      send: 'Gửi',
      stt: 'Số thứ tự',
      sttShort: 'STT',
      tag: 'Thẻ',
      billingPayment: 'Thanh toán hóa đơn',
      popup: {
        cancel: 'Đóng',
        confirm: 'Xác nhận'
      },
      button: {
        close: 'Đóng',
        delete: 'Xóa',
        save: 'Lưu',
        create: 'Tạo',
        update: 'Cập nhật'
      },
      header: {
        unpaidAmount: 'Tổng số tiền hóa đơn chưa thanh toán.',
        account: 'Tài khoản',
        billing: 'Thanh toán',
        ticket: 'Hỗ trợ'
      },
      sidebarMenu: {
        service: 'Dịch vụ',
        account: 'Tài khoản',
        billing: 'Thanh toán',
        ticket: 'Hỗ trợ',
        plan: 'Bảng giá',
        server: 'Máy chủ',
        volume: 'Volumes',
        snapshot: 'Snapshot',
        sshKeys: 'SSH Key'
      },
      errorPage: {
        des: 'Có lỗi xảy ra với hệ thống của chúng tôi.',
        please: 'Vui lòng',
        previous: 'Quay lại',
        or: 'hoặc',
        homePage: 'Về trang chủ'
      },
      auth: {
        login: {
          title: 'ĐĂNG NHẬP PORTAL CLOUD Server',
          description : 'Nhập email và mật khẩu của bạn để đăng nhập',
          btn: 'Đăng nhập',
          registerBtn: 'Tạo tài khoản mới',
          forgotPasswordBtn: 'Quên mật khẩu',
          password: {
            placeHolder: 'Mật khẩu',
            required: 'Mật khẩu không được bỏ trống.'
          },
          email: {
            placeHolder: 'Email',
            required: 'Email không được bỏ trống.'
          },
          invalid: 'Email hoặc mật khẩu không đúng.'
        },
        forgotPassword: {
          title: 'LẤY LẠI MẬT KHẨU',
          description: 'Vui lòng điền Email đã đăng ký, chúng tôi sẽ gửi đường dẫn hướng dẫn lấy lại mật khẩu vào địa chỉ Email này.',
          btn: 'Lấy lại mật khẩu',
          loginLink: 'Đăng nhập',
          email: {
            placeHolder: 'Email',
            required: 'Email không được bỏ trống.',
            type: 'Vui lòng nhập đúng định dạng email.'
          },
          notice: 'Nếu email <b>{email}</b> đã đăng ký, chúng tôi đã gửi hướng dẫn Lấy lại mật khẩu vào đó.<br>Vui lòng kiểm tra hòm thư đến hoặc spam.'
        },
        resetPassword: {
          title: 'ĐẶT LẠI MẬT KHẨU',
          btn: 'Đặt lại',
          password: {
            placeholder: 'Mật khẩu',
            required: 'Vui lòng nhập mật khẩu'
          },
          passwordConfirm: {
            placeholder: 'Nhập lại mật khẩu',
            required: 'Vui lòng nhập lại mật khẩu',
            notmatch: 'Nhập lại mật khẩu không khớp mật khẩu.'
          }
        },
        register: {
          title: 'ĐĂNG KÝ',
          description : 'Nhập email và mật khẩu của bạn để đăng ký',
          btn: 'Đăng ký',
          email: {
            placeholder: 'Email',
            required: 'Email không được bỏ trống.'
          },
          password: {
            placeholder: 'Mật khẩu',
            required: 'Mật khẩu không được bỏ trống.'
          },
          fullName: {
            placeholder: 'Họ và tên',
            required: 'Họ và tên không được bỏ trống.'
          },
          phoneNumber: {
            placeholder: 'Số điện thoại',
            required: 'Số điện thoại không được bỏ trống.',
            pattern: 'Vui lòng nhập đúng định dạng số điện thoại.'
          },
          login: 'Nếu bạn đã có tài khoản?',
          agree : 'Đồng ý với',
          term :{
            title : 'Điều khoản và chính sách',
            required: 'Vui lòng xác nhận đồng ý với điều khoản và chính sách'
          },
          privacy: 'Bằng việc ấn Đăng ký, bạn đã đồng ký với <a class="text-primary" href="https://idconline.vn/terms.html" target="_blank">Điều khoản sử dụng</a> của chúng tôi.'
        },
        verifyEmail: {
          title: 'XÁC THỰC EMAIL',
          notice: 'Chúng tôi đã gửi email xác thực đến <b>{email}</b><br>Vui lòng kiểm tra hòm thư hoặc spam và làm theo hướng dẫn.<br>Trong trường hợp không nhận được email, ấn Gửi lại mail xác thực hoặc liên hệ nhân viên hỗ trợ.',
          resend: 'Gửi lại mail xác thực',
          loginLink: 'Đăng nhập',
          notify: 'Hệ thống đã gửi mail xác thực về email của bạn.',
          verified : 'Đã xác thực',
          unconfirmed : 'Chưa xác thực',
        },
        verifyPhone: {
          title: 'XÁC THỰC SỐ ĐIỆN THOẠI',
          notice: 'Quý khách vui lòng ấn Gửi OTP để nhận mã xác thực tài khoản cho số điện thoại <b>{phone}</b><br>Trong trường hợp không nhận được OTP, ấn Gửi OTP xác thực hoặc liên hệ nhân viên hỗ trợ.',
          resend: 'Gửi OTP',
          confirm: 'Xác thực',
          loginLink: 'Đăng nhập',
          notify: 'Hệ thống đã gửi OTP xác thực về số điện thoại của bạn.',
          success: 'Xác thực số điện thoại thành công.'
        }
      },
      account: {
        userInfo: {
          title: 'Thông tin cá nhân',
          fullName: 'Họ và tên',
          phoneNumber: 'Số điện thoại'
        },
        password: {
          title: 'Mật khẩu',
          old: 'Mật khẩu cũ',
          new: 'Mật khẩu mới',
          rePassword: 'Nhập lại mật khẩu mới',
          btn: 'Đổi mật khẩu',
          rulesForm: {
            requiredOldPassword: 'Mật khẩu cũ không được bỏ trống.',
            requiredNewPassword: 'Mật khẩu mới không được bỏ trống.',
            invalidPassword: 'Mật khẩu mới không trùng khớp.'
          }
        },
        invoice: {
          title: 'Thông tin nhận hóa đơn',
          companyName: 'Tên đơn vị',
          taxCode: 'Mã số thuế',
          taxRegisteredAddress: 'Địa chỉ thuế',
          billingAddress: 'Địa chỉ nhận hóa đơn',
          billingEmail: 'Email liên hệ về hóa đơn',
          billingPhone: 'Điện thoại liên hệ về hóa đơn'
        }
      },
      billing: {
        title: 'Tài khoản thanh toán',
        balance: 'Số dư tài khoản',
        unpaidAmount: 'Dư nợ hóa đơn',
        additionalAmount: 'Số tiền cần nạp',
        topup: {
          withBalance: 'Nạp tiền bằng Tài khoản tín dụng',
          withCouponCode: 'Nạp tiền bằng mã khuyến mại',
          useVND: 'Tiền VNĐ',
          useUSD: 'Tiền USD',
          selectAmount: 'Chọn mệnh giá nạp tiền có sẵn',
          inputAmount: 'Hoặc nhập giá trị tiền nạp thủ công'
        },
        payIn: {
          title: 'Nạp tiền vào tài khoản',
          moneyType: 'Chọn loại tiền',
          exchangeRate: 'Tỷ giá',
          selectDenominationsTitle: 'Chọn mệnh giá nạp tiền',
          titleInputDenominations: 'Hoặc nhập giá trị tiền nạp',
          descDenominations: 'Bạn sẽ nạp vào tài khoản số tiền',
          btn: 'Nạp tiền'
        },
        promotionCode: {
          title: 'Mã khuyến mại',
          desc: 'Vui lòng nhập mã khuyến mại tại đây.'
        },
        invoices: {
          title: 'Danh sách hóa đơn',
          id: 'Mã hóa đơn',
          status: 'Trạng thái',
          amount: 'Số tiền',
          created: 'Ngày tạo',
          expired: 'Hạn thanh toán',
          action: 'Thao tác',
          btnPayment: 'Thanh toán',
          btnShowInvoice: 'Xem hóa đơn & Tải về',
          statusInvoice: {
            openLabel: 'Chưa thanh toán',
            paidLabel: 'Đã thanh toán',
            cancelledLabel: 'Đã hủy',
            undefinedLabel: 'Trạng thái khác',
            allLabel: 'Tất cả'
          },
          showInvoice: 'Xem',
          values: 'Tài nguyên',
        },
        paymentSuccess: {
          title: 'Giao dịch nạp tiền thanh toán thành công',
          titleInfo: 'Thông tin giao dịch',
          paymentMethod: 'Cổng thanh toán',
          price: 'Số tiền thanh toán',
          balance: 'Số dư hiện tại',
          continueBuyService: 'Tiếp tục mua dịch vụ'
        },
        paymentFailed: {
          title: 'Giao dịch nạp tiền thanh toán thất bại.'
        },
        paymentHistories: {
          title: 'Lịch sử nạp tiền',
          labelId: 'Mã giao dịch',
          labelMethod: 'Phương thức',
          labelCreated: 'Ngày giao dịch',
          labelAmount: 'Số tiền giao dịch',
          btnInvoiceDetail: 'Xem biên lai'
        },
        dialogConfirmPayment: {
          title: 'Xác nhận thanh toán',
          labelContent: 'Nội dung thanh toán',
          content: 'Nạp tiền vào Balance',
          labelAmount: 'Số tiền thanh toán',
          methods: {
            title: 'Hình thức thanh toán',
            onepay: {
              title: 'Thanh toán qua OnePAY, số tiền',
              content: `Chấp nhận thanh toán online qua: Thẻ ATM có Internet Banking,
                     các thẻ thanh toán quốc tế: Visa, Master...
                     <br><br>
                     Hệ thống sẽ chuyển hướng sang OnePAY để thực hiện giao dịch này,
                     vui lòng không đóng trình duyệt cho đến khi thanh toán hoàn tất.`,
              btn: 'Thanh toán với OnePAY'
            },
            paypal: {
              title: 'Thanh toán qua PayPal, số tiền',
              content: 'Hệ thống sẽ chuyển hướng sang PayPal để thực hiện giao dịch này, vui lòng không đóng trình duyệt cho đến khi thanh toán hoàn tất.',
              btn: 'Thanh toán với PayPal'
            },
            transfer: {
              label: 'Chuyển khoản',
              content: `Vui lòng chuyển khoản với thông tin sau:
                        <br>
                        Người nhận: Công Ty Cổ Phần Công Nghệ Và Giải Pháp Trực Tuyến IDC
                        <br>
                        Số tài khoản: 22010005201962
                        <br>
                        Tại: Ngân hàng Thương mại cổ phần Đầu tư và Phát triển Việt Nam (BIDV)
                        <br>
                        SWIFT code: BIDVVNVX
                        <br>`,
              labelAmount: 'Số tiền',
              smsContentPayIn: 'Nội dung: Nap tien dich vu',
              smsContentPayment: 'Nội dung: Thanh toan don hang'
            }
          }
        }
      },
      ticket: {
        ticketOfYou: {
          title: 'Yêu cầu hỗ trợ',
          label: 'Yêu cầu hỗ trợ của bạn',
          created: 'Ngày tạo',
          subject: 'Vấn đề',
          status: 'Trạng thái',
          detail: 'Chi tiết',
          showDetail: 'Xem chi tiết'
        },
        sendNewTicket: {
          title: 'Gửi yêu cầu hỗ trợ mới',
          labelSubject: 'Vấn đề cần hỗ trợ:',
          labelContent: 'Mô tả:',
          btn: 'Gửi yêu cầu'
        },
        detail: {
          title: 'Yêu cầu hỗ trợ số',
          subject: 'Vấn đề',
          content: 'Nội dung',
          status: 'Trạng thái',
          label: 'Trả lời:',
          btn: 'Gửi trả lời'
        }
      },
      sshKey: {
        list: {
          title: 'SSH Key đang có',
          placeholder: 'Tìm kiếm Snapshot theo tên',
          btnCreate: 'Thêm SSH key',
          btnShow: 'Xem',
          btnHide: 'Ẩn'
        },
        actionCreate: {
          title: 'Tạo SSH Key',
          name: 'Tên Key',
          descName: `Tên SSH key sẽ được dùng để hiển thị khi chọn Key lúc tạo Server. Tên Key chỉ chứa những kí tự chữ cái, số, kí tự đặc biệt gạch ngang và gạch dưới.`,
          placeholderName: 'Tên key',
          txtIsHasSshkey: 'Tên Key đã tồn tại. Vui lòng nhập tên Key khác.',
          publicKey: 'Public Key',
          descPublicKey: `Vui lòng nhập Public Key có sẵn của bạn, trường này có thể để trống.
                          <br>
                          Trong trường hợp bạn để trống, hệ thống sẽ tạo 1 cặp key mới cho bạn và Private Key sẽ được gửi vào email.`,
          btnCreate: 'Tạo Key'
        },
        actionDelete: {
          title: 'Xóa SSH Key',
          sure: 'Bạn chắc chắn xóa SSH Key',
          btn: 'Xóa SSH Key',
          successNotify: 'Xóa SSH Key thành công'
        }
      },
      snapshot: {
        title: 'Snapshot đang có',
        placeholderInputSearch: 'Tìm kiếm Snapshot theo tên',
        btnCreate: 'Tạo Snapshot',
        name: 'Tên',
        size: 'Dung lượng',
        type: 'Loại',
        created: 'Ngày tạo',
        action: 'Thao tác',
        restore: 'Restore',
        delete: 'Xóa',
        detail: {
          title: 'Thông tin Snapshot',
          volume: 'Ổ cứng'
        },
        actionRestore: {
          title: 'Khôi phục dữ liệu',
          description: 'Máy chủ sẽ khởi động lại trong quá trình khôi phục. Chọn {confirm} để bắt đầu khôi phục dữ liệu',
          selected: 'Snapshot đã chọn',
          close: 'Đóng',
          confirm: 'Xác nhận'
        },
        actionCreate: {
          title: 'Tạo Snapshot',
          desc: `Lưu ý: Tạo Snapshot khi server đang bật có thể làm hỏng dữ liệu, vui lòng tắt Server đang gắn Volume (nếu có) trước khi tạo Snapshot.
                 <br>
                 Snapshot được tạo ra sẽ có cùng loại và dung lượng với Volume đã chọn.`,
          form: {
            title: 'Tên Snapshot',
            placeholderInputName: 'Tên Snapshot',
            titleSelectVolume: 'Volume cần sao lưu',
            placeholderInputSelectVolume: 'Chọn volume',
            btnCreate: 'Tạo Snapshot',
            btnConfirm: 'Xác nhận tạo Snapshot'
          },
          invoiceSidebar: {
            title: 'Thông tin tạo snapshot',
            name: 'Tên snapshot',
            size: 'Dung lượng',
            type: 'Loại'
          }
        },
        actionDelete: {
          title: 'Xóa snapshot',
          content: 'Bạn chắc chắn xóa Snapshot',
          btnCreate: 'Xóa snapshot'
        }
      },
      dashboard: {
        title: {
          infoBalance: 'Thông tin tài chính',
          createService: 'Khởi tạo nhanh dịch vụ',
          server: 'Máy chủ đang có'
        },
        billing: {
          title: 'Số dư tài khoản',
          balance: 'Tài khoản chính',
          unpaid_amount: 'Hóa đơn chưa thanh toán',
          additional_amount: 'Cần nạp'
        },
        news: {
          greeting: 'Xin chào.',
          thank: 'Cảm ơn bạn đã sử dụng dịch vụ của IDCOnline Vps.',
          support: `Nếu gặp khó khăn trong quá trình sử dụng, vui lòng liên hệ Hotline: <a class="text-primary" href="tel:19006452">19006452</a> hoặc gửi yêu cầu <a class="text-primary" href="/idc/ticket">Hỗ trợ</a>`
        },
        orders: {
          title: 'Đơn hàng chờ thanh toán',
          id: 'Mã đơn hàng',
          info: 'Thông tin',
          clickShowDetail: 'Click để xem chi tiết đơn hàng',
          btnPayOrder: 'Thanh toán',
          btnCancelOrder: 'Hủy thanh toán',
          statusCancelled: 'Đã hủy',
          statusPending: 'Chờ thanh toán',
          btnCancel: 'Hủy bỏ',
          txtDetail: 'Chi tiết',
          detail: {
            title: 'Thông tin đơn hàng chờ thanh toán',
            label: 'Thông tin đơn hàng:',
            newConfig: 'Cấu hình mới',
            newSize: 'Dung lượng mới',
            timeOrder: 'Đơn hàng này có giá trị đến',
            pricingOrder: 'Số tiền cần thanh toán'
          }
        },
        createMore: {
          title: 'Bạn có thể tạo thêm',
          volumeDesc: 'Sử dụng Volume gắn ngoài giúp dữ liệu được an toàn và việc mở rộng dung lượng dễ dàng hơn.',
          snapshotDesc: 'Tạo Snapshot để lưu trữ bản sao ổ cứng của bạn.',
          sshKeyDesc: 'Sử dụng SSH Key để truy cập vào server sẽ an toàn hơn việc dùng Password truyền thống.'
        }
      },
      orders: {
        create: {
          buyService: 'Mua dịch vụ',
          selectConfig: 'Chọn cấu hình',
          selectPackage: 'Chọn gói cước',
          payment: 'Thanh toán',
          selectedConfig: 'Cấu hình đã chọn',
          config: 'Cấu hình',
          newConfig: 'Cấu hình mới',
          oldConfig: 'Cấu hình cũ',
          nameVolume: 'Tên Volume',
          nameSnapshot: 'Tên Snapshot',
          nameServer: 'Tên Server',
          selectedPackage: 'Chọn gói thanh toán',
          reduce: 'Giảm',
          infoPayment: 'Thông tin thanh toán',
          contentPayment: 'Nội dung thanh toán',
          buyIp: 'Mua thêm địa chỉ IP',
          createServer: 'Tạo máy chủ',
          createVolume: 'Tạo ổ cứng',
          createSnapshot: 'Tạo Snapshot',
          package: 'Gói thanh toán',
          cost: 'Chi phí',
          vat: 'Thuế',
          costOfMonth: 'Tổng chi phí',
          paymentToday: 'Thanh toán hôm nay',
          changeConfigServer: 'Thay đổi cấu hình Server',
          changeConfigVolume: 'Thay đổi cấu hình Volume',
          to: 'Tính đến',
          back: 'Quay lại',
          continuePayment: 'Tiếp tục thanh toán'
        },
        payment: {
          amount: 'Số tiền cần thanh toán',
          balance: 'Tài khoản đang có',
          moneyCharge: 'Số tiền cần nạp thêm',
          code: 'Mã giảm giá',
          apply: 'Áp dụng',
          discountAmount: 'Số tiền chiết khấu',
          grandTotal: 'Số cần thanh toán sau chiết khấu',
          reduceMoney: 'Số tiền được giảm',
          remainingAmount: 'Số tiền cần nạp thêm còn lại',
          charge: 'Nạp tiền',
          payment: 'Thanh toán đơn hàng',
          cancel: 'Hủy đơn hàng'
        }
      },
      server: {
        btnCreate: 'Tạo Server',
        btnConfirmCreate: 'Xác nhận tạo server',
        activeStatus: 'Đang hoạt động',
        shutOffStatus: 'Đang tắt',
        info: 'Thông tin',
        graphs: 'Đồ thị',
        ip: 'Quản lý IP',
        resize: 'Đổi cấu hình',
        logs: 'Log hệ thống',
        getLogs: 'Làm mới',
        warningGetLogs: 'Vui lòng bật server để xem log hệ thống.',
        invoices: 'Hóa đơn',
        deleteServer: 'Xóa server',
        getConsole: 'Truy cập qua Console',
        historiesTitle: 'Lịch sử hoạt động',
        stop: 'Tắt server',
        start: 'Bật server',
        sure: 'Chắc chắn',
        detail: {
          info: 'Thông tin',
          graphs: 'Đồ thị',
          ip: 'Quản lý IP',
          power: 'Bật/Tắt server',
          restart: 'Khởi động lại',
          resetPassword: 'Thiết lập lại mật khẩu quản trị',
          rebuild: 'Cài lại hệ điều hành',
          resize: 'Đổi cấu hình',
          volume: 'Thông tin Volumes',
          snapshot: 'Thông tin Snapshot',
          logs: 'Log hệ thống',
          invoices: 'Thông tin hóa đơn',
          destroy: 'Xóa Server'
        },
        list: {
          title: 'Server đang có',
          inputSearch: 'Tìm kiếm Server theo tên',
          otherStatus: 'Khác',
          resize: 'Thay đổi cấu hình',
          deleting: 'Xóa server ',
          manageVolume: 'Quản lý Volume',
          manageSnapshot: 'Quản lý Snapshot',
          log: 'Log hệ thống',
          btnDelete: 'Xóa',
          configView: 'Cài đặt hiển thị',
          configViewDesc: 'Lưu ý: Cần tải lại trang để cài đặt được áp dụng'
        },
        update: {
          successNotify: 'Cập nhật thông tin máy chủ thành công',
          errorNotify: 'Cập nhật thông tin máy chủ thất bại'
        },
        manageIp: {
          title: 'Quản lý IP',
          defaultIp: 'IP mặc định',
          descDefault: 'IP được cấp phát cùng Cloud Server, bạn không thể xóa bỏ',
          buyIp: 'Danh sách IP mua thêm',
          descBuyIp: 'Các IP mở rộng, gắn vào server này',
          type: 'Loại',
          createAt: 'Ngày tạo',
          buttonBuyIp: 'Mua thêm IP',
          manipulation: 'Thao tác',
          delete: 'Xóa',
          titleDelete: 'Xác nhận xóa địa chỉ IP',
          confirmText: 'Bạn có chắc chắn muốn xóa IP này không?',
          dialog: {
            title: 'Xác nhận mua thêm IP',
            ipV4: 'IPv4',
            ipV6: 'IPv6',
            quantity: 'Số lượng',
            unitPrice: 'Đơn giá',
            expenses: 'Chi phí 1 tháng',
            vat: 'Thuế VAT(%{vat}%)',
            totalMonthly: 'Tổng thanh toán hàng tháng',
            from: 'Tính đến',
            totalToday: 'Thanh toán hôm nay',
            confirm: 'Xác nhận',
            close: 'Đóng'
          },
          notify: {
            deletedIp: 'Xóa địa chỉ IP thành công.',
            deleteIpError: 'Xóa địa chỉ IP thất bại.',
            buyIpSuccess: 'Mua thêm IP thành công.',
            buyIpError: 'Mua thêm IP thất bại',
            limitIP: 'Mỗi server chỉ có tối đa 20 địa chỉ IP.'
          }
        },
        actionCreate: {
          wait_build: 'Cloud Server đang được khởi tạo, thông tin của Cloud Server sẽ được gửi tới email của bạn sau 15-30 phút.',
          title: 'Tạo Server',
          titleSelectOs: 'Chọn bộ cài',
          titlePlan: 'Sản phẩm / Dịch vụ',
          paymentTerm: 'Kỳ hạn thanh toán',
          osImage: 'Hệ điều hành',
          titleConfig: 'Cấu hình tùy chỉnh',
          typeRootDisk: 'Loại của ổ Root Disk',
          dataDisk: {
            title: 'Mua thêm ổ cứng mở rộng (Data Disk)',
            btnCreate: 'Thêm data disk',
            btnDelete: 'Xóa',
            labelPrice: 'Giá/tháng (VNĐ)'
          },
          wanIP: {
            title: 'Mua thêm IP',
            type: 'Loại',
            qty: 'Số lượng'
          },
          loginInfo: {
            title: 'Thông tin đăng nhập',
            account: 'Tài khoản quản trị',
            password: 'Mật khẩu',
            descPassword: 'Bạn cần truy cập server sau khi quá trình khởi tạo hoàn tất để thiết lập mật khẩu. Xem thêm tại <a target="_tblank" href="/static/Huong_dan_khoi_tao_VPS.pdf">Hướng dẫn khởi tạo Cloud Server</a>',
            descSshKey: `Chọn 1 key để kết nối tới server hoặc <a class="text-primary" href="/ssh-keys/create">Nhập key có sẵn tại đây</a>.`,
            uncheckSshKey: 'Bạn chưa chọn SSH key, phương thức này làm việc truy cập đến server được bảo mật hơn.',
            txtCreateSshKey: `Bạn chưa có SSH Key. Vui lòng tạo SSH Key <a href="/ssh-keys/create" class="text-primary">tại đây</a>.`,
            txtWarningSshKey: 'Bạn nên sử dụng SSH Key để đăng nhập vào server, phương thức này làm việc truy cập được bảo mật hơn.'
          },
          extra: {
            title: 'Dịch vụ bổ sung',
            placeholder: 'Gõ để tìm kiếm',
            btnCreate: 'Thêm tài nguyên',
            currencyUnit: 'VNĐ',
            uom: 'Đơn vị',
            table: {
              name: 'Tên',
              cost: 'Giá',
              unit: 'Đơn vị',
              quantity: 'Số lượng',
              btnDelete: 'Xóa'
            },
            totalPriceTxt1: 'Tổng giá trị tài nguyên mua thêm',
            totalPriceTxt2: 'nhân viên hỗ trợ sẽ liên hệ để xác nhận các tài nguyên này sau khi server khởi tạo hoàn tất.'
          },
          longTermPayment: {
            title: 'Thanh toán dài hạn'
          },
          serverInfo: {
            title: 'Thông tin server',
            name: 'Tên server',
            sshKey: {
              placeHolder: 'Chọn SSH Key'
            },
            desc: 'Tên server sẽ được sử dụng để làm Hostname. Nên bạn cần đặt tên có độ dài 5-32 ký tự, chỉ chứa những kí tự chữ cái, số, kí tự đặc biệt gạch ngang và gạch dưới.'
          }
        },
        power: {
          title: 'Bật/Tắt server',
          desc: `Bạn nên tắt server từ trong hệ điều hành để tránh các lỗi về mất mát dữ liệu có thể xảy ra.
                 Nếu không thể thực hiện được, hãy chọn "Tắt server" dưới dây.`
        },
        reboot: {
          title: 'Khởi động lại',
          desc: `Cloud Server của bạn sẽ được khởi động lại ngay lập tức. Bạn nên dùng chức năng này khi gặp các
                vấn đề về truy cập đến Cloud Server, hoặc không thể thao tác được từ trong server.`,
          selectMethod: 'Chọn phương thức khởi động lại:',
          soft_reboot: 'Máy chủ của tôi hoạt động bình thường',
          hard_reboot: 'Máy chủ của tôi đang có vấn đề về truy cập, hoặc đang bị treo',
          btn: 'Xác nhận khởi động lại',
          dialogConfirm: {
            title: 'Xác nhận khởi động lại Server',
            sure: 'Chắc chắn khởi động lại Server?',
            btn: 'Khởi động lại'
          }
        },
        resetPassword: {
          title: 'Thiết lập lại mật khẩu quản trị',
          desc: `Thiết lập lại mật khẩu server trong trường hợp bạn quên mật khẩu tài khoản quản trị hay mất SSH key để vào server.
                <br>
                Mật khẩu mới sẽ được gửi qua Email sau khi quá trình hoàn tất.`,
          btn: 'Xác nhận reset mật khẩu',
          dialogConfirm: {
            title: 'Xác nhận reset mật khẩu',
            sure: 'Chắc chắn reset mật khẩu?',
            btn: 'Reset mật khẩu'
          }
        },
        rebuild: {
          title: 'Cài lại OS',
          subTitle: 'Cài lại hệ điều hành',
          desc: 'Chức năng sẽ cài đặt hệ điều hành mới lên server của bạn. <br>Lưu ý: <ul><li class="text-danger">Dữ liệu hiện tại trên ổ Root Disk sẽ bị mất, vui lòng backup lại dữ liệu trước khi xác nhận cài lại</li><li>Các Snapshot của ổ Root Disk (nếu có) sẽ bị xóa.</li><li>Bạn sẽ nhận được email chứa mật khẩu mới của server khi quá trình hoàn tất.</li></ul>',
          selectOsImage: 'Chọn image muốn cài mới',
          serverName: 'Tên server mới',
          password: 'Mật khẩu mới',
          btn: 'Xác nhận cài lại',
          dialogConfirm: {
            title: 'Xác nhận cài lại hệ điều hành',
            sure: 'Chắc chắn cài lại hệ điều hành',
            btn: 'Chấp nhận'
          },
          rules: {
            title: 'Mật khẩu tối thiểu 10 ký tự, thỏa mãn 3 trong 4 điều kiện sau:',
            capital: 'Có ít nhất 1 ký tự chữ in hoa',
            lowercase: 'Có ít nhất 1 ký tự chữ thường',
            special: 'Có ít nhất 1 ký tự đặc biệt trong bộ !@#$%^&*+=',
            number: 'Có ít nhất 1 chữ số',
            hint: 'Hoặc có thể sử dụng mật khẩu gợi ý dưới đây:',
            message: {
              required: 'Mật khẩu không được để trống.',
              minLength: 'Mật khẩu tối thiểu 10 ký tự.',
              invalid: 'Mật khẩu mới không hợp lệ.'
            }
          }
        },
        resizeAction: {
          title: 'Chọn cấu hình',
          btn: 'Thay đổi cấu hình',
          btnConfirm: 'Xác nhận thay đổi cấu hình',
          invoiceSidebar: {
            title: 'Thông tin cấu hình',
            currentPack: 'Gói hiện tại',
            newPack: 'Gói mới',
            cost: 'Chi phí 1 tháng',
            newConfig: 'Cấu hình thay đổi'
          },
          dialogConfirm: {
            title: 'Xác nhận tạo đơn hàng'
          }
        },
        volumes: {
          btnCreate: 'Tạo volume mới',
          detach: 'Gỡ khỏi server',
          createSnapshot: 'Tạo Snapshot',
          extend: 'Tăng dung lượng'
        },
        snapshots: {
          title: 'Tạo Snapshot mới',
          btnCreate: 'Tạo Snapshot',
          name: 'Tên Snapshot',
          desc: `Snapshot là bản sao lưu của Volume, nên tạo snapshot cho Volume để đảm bảo có thể khôi phục được
                 dữ liệu khi cần thiết.`,
          selectVolume: 'Chọn ổ cứng cần sao lưu',
          listTitle: 'Danh sách Snapshot'
        },
        invoicesTable: {
          id: 'Mã hóa đơn',
          amount: 'Số tiền',
          status: 'Tình trạng',
          btnPayment: 'Thanh toán',
          btnShowInvoice: 'Xem hóa đơn & Tải về'
        },
        destroy: {
          title: 'Xóa server',
          btn: 'Xóa Server',
          saveDisk: `Xóa server và <strong>GIỮ LẠI</strong> ổ cứng.`,
          descSaveDisk: `Ổ cứng và các snapshot của server này sẽ được giữ lại sau khi xóa server, trong trường hợp bạn muốn
                        khôi phục lại dữ liệu, có thể chọn Tạo server từ ổ cứng và snapshot của server này.
                        <br>
                        Các tài nguyên đi kèm khác như IP, Bandwidth mua thêm, ... sẽ bị xóa.`,
          dontSaveDisk: `Xóa server và <strong class="text-danger">XÓA</strong> các tài nguyên mua kèm.`,
          descDontSaveDisk: ` Tất cả tài nguyên của server như ổ cứng, snapshot, ip, ... sẽ bị xóa.
                            <br>
                            Sau khi bị xóa sẽ không thể khôi phục.`,
          dialogConfirm: {
            sure: 'Chắc chắn xóa server?',
          }
        },
        invoiceSidebar: {
          title: 'Thông tin tạo server',
          name: 'Tên server',
          config: 'Cấu hình',
          size: 'Dung lượng',
          imageOs: 'Hệ điều hành',
          no: 'Không'
        },
        graphsDateTime: {
          hours: 'Giờ',
          dayAgo: 'Ngày trước'
        }
      },
      volume: {
        list: {
          title: 'Volume đang có',
          placeholder: 'Tìm kiếm Volume theo tên',
          btnCreate: 'Tạo Volume',
          name: 'Tên',
          size: 'Dung lượng',
          type: 'Loại',
          created: 'Ngày tạo',
          serverAttach: 'Server đang gắn',
          uncheckAttchServer: 'Chưa được gắn vào server',
          action: 'Thao tác',
          detach: 'Gỡ',
          attach: 'Gắn vào server',
          createSnapshot: 'Tạo snapshot',
          autoSnapshot: 'Cấu hình Snapshot tự động',
          extendSize: 'Tăng dung lượng',
          delete: 'Xóa'
        },
        update: {
          successNotify: 'Cập nhật thông tin volume thành công',
          errorNotify: 'Cập nhật thông tin volume thất bại'
        },
        updateAutoSnapshot: {
          title: 'Cấu hình Snapshot tự động',
          hint1: 'Hệ thống sẽ tự động tạo 1 bản snapshot cho Volume của bạn 1 tuần 1 lần theo ngày đã chọn.',
          hint2: 'Bản Snapshot này được miễn phí, hãy sử dụng để đảm bảo an toàn cho dữ liệu của bạn.',
          label1: 'Chọn lịch chạy backup hàng tuần',
          label2: 'Chọn giờ backup',
          dayOfWeek: 'Thứ',
          day: 'Ngày',
          Monday: 'Thứ hai',
          Tuesday: 'Thứ ba',
          Wednesday: 'Thứ tư',
          Thursday: 'Thứ năm',
          Friday: 'Thứ sáu',
          Saturday: 'Thứ bảy',
          Sunday: 'Chủ nhật',
          1: 'Thứ hai',
          2: 'Thứ ba',
          3: 'Thứ tư',
          4: 'Thứ năm',
          5: 'Thứ sáu',
          6: 'Thứ bảy',
          0: 'Chủ nhật',
          status: 'Trạng thái hoạt động',
          nextTime: 'Lần chạy tiếp theo',
          delete: 'Xóa lịch',
          active: 'Đang hoạt động',
          notify: {
            created: 'Đã tạo thành công Snapshot tự động.',
            updated: 'Cập nhật thành công.',
            deleted: 'Xóa thành công.',
            error: 'Có lỗi xảy ra !!'
          }
        },
        detail: {
          title: 'Thông tin Volume',
          serverAttach: 'Máy chủ đang gắn',
          noServerAttach: 'Chưa được gắn vào server'
        },
        actionCreate: {
          title: 'Tạo Volume',
          titleSelectSnapshot: 'Chọn snapshot',
          descSelectSnapshot: 'Nếu chọn tạo volume từ snapshot thì cấu hình volume sẽ giống cấu hình snapshot.',
          placeholderSelectSnapshot: 'Chọn snapshot',
          optionUncheckSnapshot: 'Không chọn Snapshot',
          form: {
            title: 'Tên Volume',
            desc: 'Tên volume từ 5 - 32 kí tự, chỉ chứa những kí tự chữ cái, số, kí tự đặc biệt gạch ngang và gạch dưới.',
            selectConfig: 'Chọn cấu hình',
            type: 'Loại ổ cứng:',
            size: 'Dung lượng ổ cứng: ',
            titleSelectServer: 'Chọn server để gắn',
            placeholderSelectServer: 'Chọn server',
            optionUncheckServer: 'Không chọn Server',
            btnCreate: 'Tạo Volume',
            btnConfirm: 'Xác nhận tạo Volume'
          },
          invoiceSidebar: {
            title: 'Thông tin tạo volume',
            name: 'Tên volume',
            size: 'Dung lượng',
            type: 'Loại'
          }
        },
        attach: {
          title: 'Gắn volume',
          to: 'vào',
          labelSelect: 'Chọn Server để gắn:',
          placeholder: 'Chọn Server',
          btn: 'Gắn volume'
        },
        detach: {
          title: 'Gỡ volume',
          to: 'khỏi',
          sure: 'Bạn chắc chắn gỡ Volume',
          btn: 'Gỡ volume'
        },
        extend: {
          title: 'Tăng dung lượng Volume',
          warning: `<p><strong>Lưu ý:</strong></p>
                    <ul>
                      <li>Bạn chỉ có thể tăng dung lượng của Volume, hiện tại chúng tôi không hỗ trợ giảm dung lượng</li>
                      <li>Việc tăng dung lượng Rootdisk cần phải khởi động lại server để nhận dung lượng mới.</li>
                    </ul>`,
          alertWarning: 'Vui lòng chọn dung lượng volume hiện tại lớn hơn dung lương volume gốc',
          selectNewSize: 'Chọn dung lượng mới',
          btnExtend: 'Tăng dung lượng',
          btnConfirmExtend: 'Xác nhận tăng dung lượng'
        },
        actionDelete: {
          title: 'Xóa volume',
          warning: `Chú ý: Các snapshot của volume này sẽ bị xóa cùng volume.`,
          sure: 'Bạn chắc chắn xóa Volume',
          btn: 'Xóa volume'
        },
        restore: {
          title: 'Restore',
          subTitle: 'Khôi phục dữ liệu',
          desc: 'Chức năng chỉ dùng được khi volume đã được tạo backup qua snapshot. Hãy tạo snapshot',
          descSelectSnapshot: 'Máy chủ sẽ khởi động lại trong quá trình khôi phục. Chọn snapshot để khôi phục dữ liệu cho volume:',
          btn: 'Xác nhận'
        }
      },
      invoiceSidebar: {
        show: 'Chi tiết số tiền thanh toán',
        hide: 'Thu gọn',
        pricingMonthly: 'Chi phí 1 tháng',
        pricingTotalMonthly: 'Tổng thanh toán hàng tháng',
        pricingTotalToday: 'Tổng thanh toán hôm nay',
        toDate: 'tính đến ngày',
        tax: 'Thuế VAT (10%)',
        descTax: 'Giá trên đã bao gồm 10% VAT.',
        desc: 'Đây là chi phí cho 1 tháng sử dụng, bạn sẽ chọn gói thanh toán dài hạn ở bước tiếp theo.',
        longTermPayment: 'Thanh toán trước',
        nextPaymentDate: 'ngày thanh toán tiếp theo',
        plan: {
          money: 'Chi phí',
          moneyTotal: 'Tổng thanh toán'
        }
      },
      dialogCreateOrder: {
        title: 'Xác nhận tạo đơn hàng',
        type: 'Loại tài nguyên',
        name: 'Tên tài nguyên',
        config: 'Cấu hình:',
        newConfig: 'Cấu hình mới:',
        pricingMonthly: 'Chi phí hàng tháng',
        pricingTotalToday: 'Số tiền thanh toán hôm nay',
        toDate: 'tính đến ngày',
        descTax: ' Giá trên đã bao gồm 10% VAT.',
        osImage: 'Hệ điều hành'
      },
      dialogCancelOrder: {
        title: 'Hủy đơn hàng chờ thanh toán',
        label: 'Thông tin đơn hàng',
        id: 'Mã đơn hàng',
        info: 'Thông tin',
        sure: 'Bạn chắc chắn muốn hủy đơn hàng chờ thanh toán?',
        btn: 'Hủy đơn hàng'
      },
      notify: {
        success: {
          createServer: 'Tạo máy chủ thành công.',
          createSnapshot: 'Tạo snapshot thành công.',
          createVolume: 'Tạo volume thành công.',
          reboot: 'Khởi động lại server thành công.',
          resetPassword: 'Reset password server thành công.',
          applyCouponCode: 'Áp dụng mã khuyến mại thành công.',
          payAnInvoice: 'Thanh toán hóa đơn thành công.',
          extendVolume: 'Tăng dung lượng volume thành công.',
          registerAccount: 'Đăng ký tài khoản mới thành công.',
          detachVolume: 'Gỡ volume khỏi máy chủ thành công.',
          attachVolume: 'Gắn volume vào máy chủ thành công.',
          cancelAnOrder: 'Hủy đơn hàng thành công',
          deleteSnapshot: 'Xóa Snapshot thành công.',
          retrieveServer: 'Xóa server thành công',
          retrieveVolume: 'Xóa volume thành công',
          retrieveSnapshot: 'Xóa snapshot thành công',
        },
        limitQuota: 'Vượt giới hạn tài nguyên, vui lòng liên hệ Support để tăng giới hạn.',
        error: 'Có lỗi xảy ra. Vui lòng thử lại sau. ',
        warning: {
          resizeServer: 'Đang thay đổi cấu hình. Vui lòng chờ trong giây lát.',
          rebuildServer: 'Đang cài đặt lại hệ điều hành. Vui lòng chờ trong giây lát.',
          destroyServer: 'Đang xóa server. Vui lòng chờ trong giây lát.',
          invalidCoupon: 'Mã giảm giá không hợp lệ.'
        },
        hasExpiredInvoices: 'Bạn có hóa đơn quá hạn thanh toán, xin vui lòng truy cập vào trang Thanh toán để nạp tiền và tiếp tục sử dụng dịch vụ.',
        freeTrialExperience: 'Tài khoản của bạn đủ điều kiện để dùng thử, vui lòng đăng ký để được trải nghiệm miễn phí.',
        trialExpires: 'Tài khoản của bạn đã hết hạn dùng thử. Vui lòng nâng cấp tài khoản để tiếp tục sử dụng dịch vụ.',
        inTrialTxt1: 'Tài khoản của bạn sẽ kết thúc thời gian dùng thử vào ngày',
        inTrialTxt2: 'vui lòng thành toán để tiếp tục sử dụng.'
      },
      upgradeAccount: {
        title: 'Thanh toán sử dụng',
        description: 'Tài khoản của bạn đang ở trạng thái {trial} miễn phí.',
        paymentHint: 'Để tiếp tục sử dụng dịch vụ, vui lòng thanh toán sử dụng các tài nguyên đang có trước ngày {date}.',
        notify: 'Sau thời gian trên, chúng tôi sẽ tạm dừng dịch vụ của bạn.',
        btnPayment: 'Thanh toán',
        btnPayIn: 'Nạp tiền',
        trial: 'DÙNG THỬ',
        detail: 'Chi tiết chi phí sử dụng',
        table: {
          info: 'Thông tin cấu hình',
          package: 'Gói sử dụng',
          startDate: 'Từ ngày',
          endDate: 'Đến ngày',
          fee: 'Chi phí',
          total: 'Tổng'
        }
      },
      payAnOrder: {
        title: 'Thanh toán đơn hàng',
        balance: 'Số dư hiện tại',
        amountPay: 'Số tiền cần thanh toán',
        balanceAfterTransaction: 'Số dư sau giao dịch',
        needCharge: 'Cần nạp thêm',
        discountCode: 'Mã giảm giá',
        btnPayment: 'Thanh toán'
      },
      payAnInvoice: {
        title: 'Thanh toán hóa đơn',
        amount: 'Thành tiền',
        discount: 'Chiết khấu',
        needCharge: 'Cần thanh toán',
        promotionCode: 'Mã khuyến mại',
        havePromotion: 'Hóa đơn đã được áp dụng chương trình chiết khấu khác.'
      },
      rulesForm: {
        requireTextField: 'Không được để trống.',
        requiredFullName: 'Họ và tên không được bỏ trống.',
        invalidEmail: 'Vui lòng nhập email đúng định dạng.',
        invalidPassword: 'Mật khẩu từ 5 kí tự trở lên.',
        requiredPhoneNumber: 'Số điện thoại không được bỏ trống.',
        invalidPhoneNumber: 'Vui lòng nhập đúng định dạng số điện thoại.',
        requiredSubjectTicket: 'Vấn đề hỗ trợ không được để trống.',
        requiredContentTicket: 'Mô tả hỗ trợ không được để trống.',
        requiredSshKey: 'Tên Key không được để trống.',
        invalidSshKey: 'Tên Key chỉ chứa những kí tự chữ cái, số, kí tự đặc biệt gạch ngang và gạch dưới.',
        requiredSnapshotName: 'Tên snapshot không được để trống.',
        invalidSnapshotName: 'Tên snapshot chỉ chứa những kí tự chữ cái, số, kí tự đặc biệt gạch ngang và gạch dưới.',
        invalidSnapshotCharactersLimited: 'Tên snapshot từ 5 - 32 kí tự.',
        requiredVolumeIdSnapshot: 'Volume cần sao lưu không được để trống.',
        requiredVolumeName: 'Tên volume không được để trống.',
        invalidVolumeCharactersLimited: 'Tên volume từ 5 - 32 kí tự.',
        invalidVolumeName: 'Tên volume chỉ chứa những kí tự chữ cái, số, kí tự đặc biệt gạch ngang và gạch dưới.',
        requiredServerName: 'Tên server không được bỏ trống.',
        invalidServerCharactersLimited: 'Tên server từ 3 đến 32 kí tự.',
        invalidServerName: 'Tên server chỉ chứa những kí tự chữ cái, số, kí tự đặc biệt gạch ngang và gạch dưới.'
      },
      hasPermission: {
        msg: 'Bạn không thể thực hiện thao tác này do: ',
        hasExpiredInvoices: 'có hóa đơn quá hạn thanh toán',
        trialExpired: 'hết hạn dùng thử',
        emailVerified: 'chưa xác thực email'
      },
      confirmOtp: {
        mess: 'Một mã xác thực đã được gửi đến địa chỉ email của bạn!',
        placeholder: 'Nhập mã xác thực'
      },
      plan: {
        title: 'Bảng giá sản phẩm',
        longTermPaymentChoose: 'Chọn kỳ hạn thanh toán',
        longTermPayment: 'Kỳ hạn thanh toán',
        buyBtn: 'Chọn mua'
      },
      agency: {
        title: 'Đại lý',
        user: 'Tài khoản',
        user_create: 'Tạo tài khoản',
        invoice: 'Hóa đơn',
        account: 'Tài khoản',
        report: {
          title: 'Báo cáo',
          overview: 'Tổng quan'
        }
      }
    },
    en: {
      langs: [
        {
          title: 'Tiếng Việt',
          img: 'icon-flag-vi',
          value: 'vi'
        },
        {
          title: 'English',
          img: 'icon-flag-en',
          value: 'en'
        }
      ],
      home: 'Home',
      supportChannel: 'Support channel',
      currencyUnit: 'VNĐ',
      send: 'Send',
      accountMenuTxt: 'Account',
      billingMenuTxt: 'Billing',
      signOutMenuTxt: 'Sign out',
      support: 'Ticket',
      create: 'Create',
      status: 'Status',
      name: 'Name',
      email: 'Email',
      size: 'Size',
      type: 'Type',
      action: 'Action',
      config: 'Detail',
      created: 'Created at',
      expired: 'Due date',
      version: 'Version',
      info: 'Information',
      viewAll: 'Show all',
      no: 'No',
      update: 'Update',
      confirm: 'Confirm',
      delete: 'Delete',
      selectTime: 'Select',
      months: 'months',
      here: 'here',
      stt: 'Numerical order',
      sttShort: '#',
      tag: 'Tag',
      billingPayment: 'Thanh toán hóa đơn',
      errorPage: {
        des: 'An error occurred with our system.',
        please: 'Please',
        previous: 'Come back',
        or: 'or',
        homePage: 'Return Home Page'
      },
      button: {
        create: 'Create',
        delete: 'Delete',
        update: 'Update',
        close: 'close'
      },
      auth: {
        login: {
          title: 'SIGN IN PORTAL CLOUD Server',
          description : 'Enter your email and password to sign in',
          btn: 'Sign in',
          registerBtn: 'Don\'t have an account? Sign up',
          forgotPasswordBtn: 'Forgot Password?',
          password: {
            placeHolder: 'Password',
            required: 'Password is not blank.'
          },
          email: {
            placeHolder: 'Email',
            required: 'Email is not blank.'
          },
          invalid : 'Email or password is not correct'
        },
        forgotPassword: {
          title: 'FORGOT PASSWORD',
          description: 'Enter the email address associated with your account and we will send you a link to reset your password.',
          btn: 'Request password resset',
          loginLink: 'Sign in',
          email: {
            placeHolder: 'Email',
            required: 'Email is not blank.',
            type: 'Email is invalid'
          },
          notice: 'We sent you a link to reset your password.<br>Please check Spam or Junk folder.'
        },
        resetPassword: {
          title: 'RESET PASSWORD',
          btn: 'Reset',
          password: {
            placeholder: 'Password',
            required: 'Password is not blank.'
          },
          passwordConfirm: {
            placeholder: 'Re-enter your password',
            required: 'Password is not blank.',
            notmatch: 'Passwords does not match.'
          }
        },
        register: {
          title: 'CREATE ACCOUNT',
          description : 'Use these awesome forms to login or create new account in your project for free.',
          btn: 'Sign up',
          email: {
            placeholder: 'Email',
            required: 'Email is not blank.'
          },
          password: {
            placeholder: 'Password',
            required: 'Password is not blank.'
          },
          fullName: {
            placeholder: 'Full name',
            required: 'Full name is not blank.'
          },
          phoneNumber: {
            placeholder: 'Phone',
            required: 'Phone is not blank.',
            pattern: 'Phone is invalid'
          },
          login: 'Already have an account?',
          agree : 'I agree the',
          term :{
            title : 'Terms and Conditions',
            required: 'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy.'
          },
          privacy: 'By signing up you agree to the <a class="text-primary" href="https://idconline.vn/terms.html" target="_blank">Terms of Service and Privacy Policy</a>.'
        },
        verifyEmail: {
          title: 'EMAIL VERIFICATION',
          notice: 'We sent you a verification email.<br>Please check Spam or Junk folder.',
          resend: 'Resend verfication link',
          loginLink: 'Sign in',
          notify: 'Send verification email successfully.',
          verified : 'Verified',
          unconfirmed : 'Unconfirmed',
        },
        verifyPhone: {
          title: 'PHONE VERIFICATION',
          notice: 'Please click Send OTP to receive account verification code for phone number <b>{phone}</b><br>In case you do not receive OTP, press Send OTP or contact support staff.',
          resend: 'Verfication OTP',
          confirm: 'Confirm',
          loginLink: 'Sign in',
          notify: 'Send verification OTP successfully.',
          success: 'Verification OTP successfully.'
        }
      },
      dashboard: {
        title: {
          infoBalance: 'Billing Information',
          createService: ' Getting started',
          server: 'Servers'
        },
        billing: {
          title: 'Billing information',
          balance: 'Balance',
          unpaid_amount: 'Amount you owe',
          additional_amount: 'Top up amount'
        },
        news: {
          greeting: 'Welcome.',
          thank: 'Thank you for choosing IDC Online Vps.',
          support: `If you have any questions or problems, please contact our Customer Support, hotline <a class="text-primary" href="tel:19006452">19006452</a> or <a class="text-primary" href="/idc/ticket">create Ticket</a>`
        },
        orders: {
          title: 'Orders',
          id: 'Order ID',
          info: 'Detail',
          clickShowDetail: 'Show detail',
          btnPayOrder: 'Checkout',
          btnCancelOrder: 'Cancel',
          statusCancelled: 'Cancelled',
          statusPending: 'Pending',
          btnCancel: 'Cancel',
          txtDetail: 'Detail',
          detail: {
            title: 'Order Information',
            label: 'Detail:',
            newConfig: 'New specification',
            newSize: 'New size',
            timeOrder: 'Expired at',
            pricingOrder: 'Amount'
          }
        },
        createMore: {
          title: 'Create something new',
          volumeDesc: 'A flexible way to keep your data safely and scale up.',
          snapshotDesc: 'Backup your Volume with create a Snapshot.',
          sshKeyDesc: 'SSH keys are more robust than passwords against compromises on the server side'
        }
      },
      orders: {
        create: {
          buyService: 'Create order',
          selectConfig: 'Configuration',
          selectPackage: 'Choose plan',
          payment: 'Checkout',
          selectedConfig: 'Specification',
          config: 'Configuration',
          newConfig: 'New specification',
          oldConfig: 'Old specification',
          nameVolume: 'Name',
          nameSnapshot: 'Name',
          nameServer: 'Name',
          selectedPackage: 'Choose plan',
          reduce: 'Discount',
          infoPayment: 'Order Information',
          contentPayment: 'Description',
          buyIp: 'Buy IP',
          createServer: 'Create server',
          createVolume: 'Create volume',
          createSnapshot: 'Create Snapshot',
          package: 'Plan',
          cost: 'Sub Total',
          vat: 'Fee',
          costOfMonth: 'Total',
          paymentToday: 'Order amount',
          changeConfigServer: 'Resize Server',
          changeConfigVolume: 'Extend Volume',
          from: 'from',
          to: 'to',
          back: 'Back',
          continuePayment: 'Create order'
        },
        payment: {
          amount: 'Total',
          balance: 'Balance',
          moneyCharge: 'Topup',
          code: 'Coupon',
          apply: 'Apply',
          reduceMoney: 'Discount',
          remainingAmount: 'Topup',
          discountAmount: 'Discount amount',
          grandTotal: 'Grand total',
          charge: 'Topup amount',
          payment: 'Pay this order',
          cancel: 'Cancel order '
        }
      },
      server: {
        btnCreate: 'Create Server',
        btnConfirmCreate: 'Create Server',
        activeStatus: 'Active',
        shutOffStatus: 'Shutoff',
        info: 'Information',
        graphs: 'Graph',
        ip: 'Manage IP',
        resize: 'Resize',
        logs: 'Log',
        getLogs: 'Refresh',
        warningGetLogs: 'Please power on server.',
        invoices: 'Invoices',
        deleteServer: 'Delete',
        getConsole: 'View Console',
        historiesTitle: 'Histories',
        stop: 'Power Off',
        start: 'Power On',
        sure: 'Are you sure',
        list: {
          title: 'Servers',
          inputSearch: 'Search server by name',
          otherStatus: 'Other',
          resize: 'Resize',
          deleting: 'Delete',
          manageVolume: 'Volumes',
          manageSnapshot: 'Snapshots',
          log: 'Log',
          btnDelete: 'Delete',
          configView: 'Display settings',
          configViewDesc: 'Note: A page reload is required for the settings to take effect'
        },
        update: {
          successNotify: 'Update server information successfully',
          errorNotify: 'Server information update failed'
        },
        manageIp: {
          title: 'Manage IP',
          defaultIp: 'Default',
          descDefault: 'IP default allocated with server, can not detach',
          buyIp: 'Extra IP',
          descBuyIp: 'Buy more IP then attach to this server',
          type: 'Version',
          createAt: 'Created',
          buttonBuyIp: 'Buy more IP',
          manipulation: 'Action',
          delete: 'Delete',
          titleDelete: 'Confirm delete',
          confirmText: 'Detach this IP from your server, it can not undo?',
          dialog: {
            title: 'Confirm buy new IP',
            ipV4: 'IPv4',
            ipV6: 'IPv6',
            quantity: 'Quantity',
            unitPrice: 'Unit price',
            expenses: 'Total (per month)',
            vat: 'VAT(%{vat}%) fee',
            totalMonthly: 'Grand total',
            from: 'from',
            totalToday: 'Order total',
            confirm: 'Create order',
            close: 'Close'
          },
          notify: {
            deletedIp: 'IP deleted.',
            deleteIpError: 'Error when delete IP.',
            buyIpSuccess: 'IP created.',
            buyIpError: 'Error when create new IP',
            limitIP: 'The number of IP is reached limit on this server.'
          }
        },
        actionCreate: {
          wait_build: 'Cloud Server is being initialized, Cloud Server information will be sent to your email after 15-30 minutes.',
          title: 'Create Server',
          titleSelectOs: 'Choose an image',
          titlePlan: 'Product / Service',
          paymentTerm: 'Payment Term',
          osImage: 'OS',
          titleConfig: 'Choose a config',
          typeRootDisk: 'Root Disk type',
          dataDisk: {
            title: 'Data Disk',
            btnCreate: 'Add Data Disk',
            btnDelete: 'Remove',
            labelPrice: 'Price (VNĐ)'
          },
          wanIP: {
            title: 'Buy IP',
            type: 'Version',
            qty: 'Quantity'
          },
          loginInfo: {
            title: 'Authentication',
            account: 'Login account',
            password: 'Password',
            descPassword: 'You need to access the server after the initialization is complete to set up the password. See more at <a target="_tblank" href="/static/Huong_dan_khoi_tao_VPS.pdf">Instructions to initialize Cloud Server</a>',
            descSshKey: `Choose a SSH Key for authentication or <a class="text-primary" href="/ssh-keys/create">import a new key</a>.`,
            uncheckSshKey: 'SSH Key is a more secure authentication method, please choose one.',
            txtCreateSshKey: `You have no SSH Key. Please create or import your new key <a href="/ssh-keys/create" class="text-primary">here</a>.`,
            txtWarningSshKey: 'SSH Key is a more secure authentication method.'
          },
          serverInfo: {
            title: 'Information',
            name: 'Server Hostname',
            sshKey: {
              placeHolder: 'Select SSH Key'
            },
            desc: 'Name must be between 5 and 32 characters long, the alphabet in upper or lower case, number, dash and hyphen are accepted.'
          },
          extra: {
            title: 'Buy extra product',
            placeholder: 'Search',
            btnCreate: 'Add',
            currencyUnit: 'VNĐ',
            uom: 'Unit',
            table: {
              name: 'Name',
              cost: 'Price',
              unit: 'Uom',
              quantity: 'Quantity',
              btnDelete: 'Delete'
            },
            totalPriceTxt1: 'Total extra product price',
            totalPriceTxt2: 'we will contact for extra product creation once server booted successfully.'
          },
          longTermPayment: {
            title: 'Long-term payment plan'
          }
        },
        power: {
          title: 'Power',
          desc: `We recommend power off server from inside to prevent crash your data.
                 In case you can't log in to the server, please choose "Power Off".`
        },
        reboot: {
          title: 'Restart',
          desc: `If your server is hung or you can't log in, you must restart server`,
          selectMethod: 'Please choose the restart method:',
          soft_reboot: 'Your server works normally',
          hard_reboot: 'Your server is hung',
          btn: 'Confirm',
          dialogConfirm: {
            title: 'Confirm restart',
            sure: 'Restart my server?',
            btn: 'Confirm'
          }
        },
        resetPassword: {
          title: 'Reset password',
          desc: `In case you lost access to your server with Password or SSH Key.
                <br>
                A new password will be sent to your mail when progress is complete.`,
          btn: 'Confirm',
          dialogConfirm: {
            title: 'Confirm reset password',
            sure: 'Are you sure reset your server administrator password?',
            btn: 'Confirm'
          }
        },
        rebuild: {
          title: 'Rebuild',
          subTitle: 'Rebuild',
          desc: 'Installing new OS on your server. <br>Note: <ul><li class="text-danger">This process will destroy data on Root Disk of your server, please take backup.</li><li>All Volume Snapshots will be deleted.</li><li>Please check your email for new server password after process compeleted.</li></ul>',
          selectOsImage: 'Select OS image',
          serverName: 'New server name',
          password: 'New password',
          btn: 'Rebuild server',
          dialogConfirm: {
            title: 'Confirm rebuild server',
            sure: 'Are you sure rebuild your server.',
            btn: 'Confirm'
          },
          rules: {
            title: 'Password must be at least 10 characters, satisfying 3 of the following 4 conditions:',
            capital: 'There is at least 1 uppercase character',
            lowercase: 'There is at least 1 lowercase character',
            special: 'There is at least 1 special character in the set !@#$%^&*+=',
            number: 'There is at least 1 digit',
            hint: 'Or you can use the password suggested below:',
            message: {
              required: 'Password can not be blank.',
              minLength: 'Password is at least 10 characters.',
              invalid: 'The new password is not valid.'
            }
          }
        },
        resizeAction: {
          title: 'Resize',
          btn: 'Change',
          btnConfirm: 'Confirm resize',
          invoiceSidebar: {
            title: 'Server Information',
            currentPack: 'Current plan',
            newPack: 'New plan',
            cost: 'Billed monthly',
            newConfig: 'Change'
          },
          dialogConfirm: {
            title: 'Confirm order'
          }
        },
        volumes: {
          btnCreate: 'Create volume',
          detach: 'Detach',
          createSnapshot: 'Create Snapshot',
          extend: 'Resize'
        },
        snapshots: {
          title: 'Snapshots',
          btnCreate: 'Create Snapshot',
          name: 'name',
          desc: `Snapshot is a method for backup your Volume. You can rollback Volume to Snapshot.`,
          selectVolume: 'Choose a volume',
          listTitle: 'Snapshots'
        },
        invoicesTable: {
          id: 'Invoice ID',
          amount: 'Amount',
          status: 'Status',
          btnPayment: 'Checkout',
          btnShowInvoice: 'View & Download'
        },
        destroy: {
          title: 'Delete',
          btn: 'Delete Server',
          saveDisk: `Delete server and <strong>KEEP</strong> its Volumes.`,
          descSaveDisk: `All attached Volumes and it's Snapshots will NOT be deleted. You can reuse it later.
                        <br>
                        Addional resources, like IP, Bandwidth,.. will be deleted.`,
          dontSaveDisk: `Delete server and <strong class="text-danger">DELETE</strong> all attached resources.`,
          descDontSaveDisk: `We will delete all server's resources, Ex: attached volumes and it snapshots, IP, Bandwith,...
                            <br>
                            They are not recoverable.`,
          dialogConfirm: {
            sure: 'Are you sure delete your server?',
          }
        },
        invoiceSidebar: {
          title: 'Server Information',
          name: 'Name',
          config: 'Plan',
          size: 'Size',
          imageOs: 'OS',
          no: 'None'
        },
        graphsDateTime: {
          hours: 'Hours',
          dayAgo: 'Day a go'
        }
      },
      invoiceSidebar: {
        show: 'Summary',
        hide: 'Collapse',
        pricingMonthly: 'Billed monthly',
        pricingTotalMonthly: 'Grant Total',
        pricingTotalToday: 'Order total',
        toDate: 'to',
        tax: 'VAT (10%)',
        descTax: 'Inclues VAT.',
        desc: 'Price for 1 month plan, select your subcription plan in next step.',
        longTermPayment: 'Payment plan',
        nextPaymentDate: 'your next payment date',
        plan: {
          money: 'Money',
          moneyTotal: 'Money total'
        }
      },
      rulesForm: {
        requireTextField: 'is not blank.',
        requiredFullName: 'Name is not blank.',
        invalidEmail: 'Email is invalid.',
        invalidPassword: 'Password must more than 5 character',
        requiredPhoneNumber: 'Phone is not blank.',
        invalidPhoneNumber: 'Phone is invalid.',
        requiredSubjectTicket: 'Subject is not blank.',
        requiredContentTicket: 'Description is not blank.',
        requiredSshKey: 'SSH Key name is not blank.',
        invalidSshKey: 'Name can only contain upper or lower case, number, dash and hyphen.',
        requiredSnapshotName: 'Snapshot name is not blank.',
        invalidSnapshotName: 'Name can only contain upper or lower case, number, dash and hyphen.',
        invalidSnapshotCharactersLimited: 'Name must be between 5 and 32 characters long.',
        requiredVolumeIdSnapshot: 'Volume is not blank.',
        requiredVolumeName: 'Snapshot name is not blank.',
        invalidVolumeCharactersLimited: 'Name must be between 5 and 32 characters long.',
        invalidVolumeName: 'Name can only contain upper or lower case, number, dash and hyphen.',
        requiredServerName: 'Server name is not blank.',
        invalidServerCharactersLimited: 'Name must be between 5 and 32 characters long.',
        invalidServerName: 'Name can only contain upper or lower case, number, dash and hyphen.'
      },
      hasPermission: {
        msg: 'Your action couldn\'t complete, because: ',
        hasExpiredInvoices: 'you have overdue invoice',
        trialExpired: 'your trial is ended',
        emailVerified: 'your email is unverified'
      },
      popup: {
        cancel: 'Close',
        confirm: 'Confirm'
      },
      notify: {
        success: {
          createServer: 'Create new Server success.',
          createSnapshot: 'Create new Snapshot success.',
          createVolume: 'Create new Volume success.',
          reboot: 'Server is restarted.',
          resetPassword: 'Reset password success.',
          applyCouponCode: 'Coupon applied.',
          payAnInvoice: 'Charge success.',
          extendVolume: 'Resize volume success.',
          registerAccount: 'Account successfully created.',
          detachVolume: 'Detach volume success.',
          attachVolume: 'Attach volume success.',
          cancelAnOrder: 'Canceled success',
          deleteSnapshot: 'Delete snapshot successfully.'
        },
        limitQuota: 'Exceed resource quota limit. Please contact Support for increment',
        error: 'Something went wrong. Please try again or contact support. ',
        warning: {
          resizeServer: 'Server is resizing...',
          rebuildServer: 'Server is rebuilding...',
          destroyServer: 'Server is deleting...',
          invalidCoupon: 'Coupon code is invalid'
        },
        hasExpiredInvoices: 'You have overdue invoice, please go to Billing page to checkout.',
        freeTrialExperience: 'You have 7 days free trial, please register Trial Plan for starting.',
        trialExpires: 'Your trial plan is ended, please Upgrade Plan right now.',
        inTrialTxt1: 'Your trial plan will end at',
        inTrialTxt2: 'please remind to upgrade before it ends.'
      },
      volume: {
        list: {
          title: 'Volumes',
          placeholder: 'Search Volume by name',
          btnCreate: 'Create',
          name: 'Name',
          size: 'Size',
          type: 'Type',
          created: 'Created at',
          serverAttach: 'Attached to',
          uncheckAttchServer: 'None',
          action: 'Action',
          detach: 'Detach',
          attach: 'Attach',
          autoSnapshot: 'Automatic Snapshot',
          createSnapshot: 'Create Snapshot',
          extendSize: 'Resize',
          delete: 'Delete'
        },
        update: {
          successNotify: 'Update volume information successfully',
          errorNotify: 'Volume information update failed'
        },
        updateAutoSnapshot: {
          title: 'Automatic Snapshot',
          hint1: 'The snapshot will create automatically weekly by the day you choose. Just one recent backup is stored, older backups are removed after each scheduled interval.',
          hint2: 'Snapshot, created from Automatic Snapshot, is free. You can restore the entire Volume from the snapshot.',
          label1: 'Choose your schedule for this volume',
          label2: 'Choose time to backup',
          dayOfWeek: 'Thứ',
          day: 'day',
          Monday: 'Monday',
          Tuesday: 'Tuesday',
          Wednesday: 'Wednesday',
          Thursday: 'Thursday',
          Friday: 'Friday',
          Saturday: 'Saturday',
          Sunday: 'Sunday',
          1: 'Thứ hai',
          2: 'Thứ ba',
          3: 'Thứ tư',
          4: 'Thứ năm',
          5: 'Thứ sáu',
          6: 'Thứ bảy',
          7: 'Chủ nhật',
          status: 'Active status',
          nextTime: 'Next run',
          delete: 'Delete calendar',
          active: 'Active',
          notify: {
            created: 'Created successfully Auto Snapshot.',
            updated: 'Update successful.',
            deleted: 'Delete successful.',
            error: 'An error occurred !!'
          }
        },
        detail: {
          title: 'Volume Information',
          serverAttach: 'Attached to',
          noServerAttach: 'None'
        },
        actionCreate: {
          title: 'Create Volume',
          titleSelectSnapshot: 'Choose a snapshot',
          descSelectSnapshot: 'Type of new volume is the same with type or chosen snapshot.',
          placeholderSelectSnapshot: 'Choose a snapshot',
          optionUncheckSnapshot: 'None',
          form: {
            title: 'Volume name',
            desc: 'Name must be between 5 and 32 characters long. Name can only contain upper or lower case, number, dash and hyphen.',
            selectConfig: 'Plan',
            type: 'Type:',
            size: 'Size:',
            titleSelectServer: 'Attached to',
            placeholderSelectServer: 'Choose a server',
            optionUncheckServer: 'None',
            btnCreate: 'Create',
            btnConfirm: 'Confirm'
          },
          invoiceSidebar: {
            title: 'Volume Information',
            name: 'Name',
            size: 'Size',
            type: 'Type'
          }
        },
        attach: {
          title: 'Attach Volume',
          to: 'to',
          labelSelect: 'Choose a server:',
          placeholder: 'Choose a server',
          btn: 'Attach'
        },
        detach: {
          title: 'Detach Volume',
          to: 'from',
          sure: 'Are you sure to detach this volume?',
          btn: 'Detach'
        },
        extend: {
          title: 'Resize Volume',
          warning: `<p><strong>Attention:</strong></p>
                    <ul>
                      <li>New size must larger than old size. We do not support decrease volume size.</li>
                      <li>You must restart attached server for new size detection.</li>
                    </ul>`,
          alertWarning: 'New size must larger than old size',
          selectNewSize: 'Please choose new size',
          btnExtend: 'Resize',
          btnConfirmExtend: 'Confirm'
        },
        actionDelete: {
          title: 'Delete Volume',
          warning: `<b>Attention</b>: All volume's snapshots will be delete.`,
          sure: 'Are you sure delete this Volume?',
          btn: 'Delete'
        },
        restore: {
          title: 'Restore',
          subTitle: 'Restore',
          desc: 'This function is only available when the volume has at least one snapshot. Create snapshot',
          descSelectSnapshot: 'The server will reboot after you confirm. Choose a snapshot for volume data restore.',
          btn: 'Restore'
        }
      },
      dialogCreateOrder: {
        title: 'Confirm New Order',
        type: 'Resource type',
        name: 'Name',
        config: 'Plan:',
        newConfig: 'New plan:',
        pricingMonthly: 'Billed monthly',
        pricingTotalToday: 'Order total',
        toDate: 'to',
        descTax: 'Includes VAT.',
        osImage: 'OS'
      },
      dialogCancelOrder: {
        title: 'Cancel order',
        label: 'Order Information',
        id: 'Order ID',
        info: 'Detail',
        sure: 'Cancel this order?',
        btn: 'Cancel'
      },
      upgradeAccount: {
        title: 'Upgrade Trial',
        btnPayment: 'Checkout',
        btnPayIn: 'Top up',
        valid: {
          txt1: 'After checkout, your balance will be charge',
          txt2: `this month subscription will end at {endplan}.`
        },
        invalid: {
          pleasePayIn: 'Top up',
          continueUsingService: 'for this month subscription',
          txt: `It will end at {endplan}.`
        }
      },
      payAnOrder: {
        title: 'Checkout',
        balance: 'Current balance',
        amountPay: 'Amount',
        balanceAfterTransaction: 'Balance',
        needCharge: 'Top up amount',
        discountCode: 'Coupon',
        btnPayment: 'Checkout'
      },
      payAnInvoice: {
        title: 'Checkout',
        amount: 'Subtotal',
        discount: 'Discount',
        needCharge: 'Grand total',
        promotionCode: 'Coupon',
        havePromotion: 'This invoice is already applied promotion.'
      },
      snapshot: {
        title: 'Snapshots',
        placeholderInputSearch: 'Search snapshot by name',
        btnCreate: 'Create',
        name: 'Name',
        size: 'Size',
        type: 'Type',
        created: 'Created at',
        action: 'Action',
        detail: {
          title: 'Detail',
          volume: 'Volume'
        },
        actionCreate: {
          title: 'Create Snapshot',
          desc: `Attention: Please power off the attaching Server before create Snapshot to prevent damage to the data.
                 <br>
                 Size and type of new Snapshot is the same with chosen Volume.`,
          form: {
            title: 'Name',
            placeholderInputName: 'Name',
            titleSelectVolume: 'Volume',
            placeholderInputSelectVolume: 'Choose a volume',
            btnCreate: 'Create',
            btnConfirm: 'Confirm'
          },
          invoiceSidebar: {
            title: 'Snapshot Information',
            name: 'Name',
            size: 'Size',
            type: 'Type'
          }
        },
        actionDelete: {
          title: 'Delete',
          content: 'Delete this Snapshot?',
          btnCreate: 'Delete'
        }
      },
      account: {
        userInfo: {
          title: 'Personal Information',
          fullName: 'Name',
          phoneNumber: 'Phone'
        },
        password: {
          title: 'Password',
          old: 'Old password',
          new: 'New password',
          rePassword: 'Confirm password',
          replaceState: 'Enter the new password',
          btn: 'Change password',
          rulesForm: {
            requiredOldPassword: 'Old password is not blank.',
            requiredNewPassword: 'New password is not blank.',
            invalidPassword: 'The new password does not match.'
          }
        },
        invoice: {
          title: 'Billing Information',
          companyName: 'Name Org',
          taxCode: 'Tax code',
          taxRegisteredAddress: 'Tax Registration Address',
          billingAddress: 'Billing Address',
          billingEmail: 'Billing Email',
          billingPhone: 'Billing Phone'
        }
      },
      ticket: {
        ticketOfYou: {
          title: 'Tickets',
          label: 'Your ticket',
          created: 'Created at',
          subject: 'Subject',
          status: 'Status',
          detail: 'Action',
          showDetail: 'Detail'
        },
        sendNewTicket: {
          title: 'Create new Ticket',
          labelSubject: 'Subject:',
          labelContent: 'Description:',
          btn: 'Send'
        },
        detail: {
          title: 'Ticket number',
          subject: 'Subject',
          content: 'Description',
          status: 'Status',
          label: 'Reply:',
          btn: 'Reply'
        }
      },
      header: {
        unpaidAmount: 'Amount You Owe.',
        account: 'Account',
        billing: 'Billing',
        ticket: 'Ticket'
      },
      sidebarMenu: {
        service: 'Services',
        account: 'Account',
        billing: 'Billing',
        ticket: 'Ticket',
        plan: 'Pricing',
        server: 'Servers',
        volume: 'Volumes',
        snapshot: 'Snapshot',
        sshKeys: 'SSH Key'
      },
      sshKey: {
        list: {
          title: 'SSH Keys',
          placeholder: 'Search by name',
          btnCreate: 'Create',
          btnShow: 'Show',
          btnHide: 'Hide'
        },
        actionCreate: {
          title: 'Create SSH Key',
          name: 'Name ',
          descName: `Name can only contain upper or lower case, number, dash and hyphen.`,
          placeholderName: 'Name',
          txtIsHasSshkey: 'This name is already exist, please select again.',
          publicKey: 'Public Key',
          descPublicKey: `Please enter your Public Key, if not, we will generate a new Key pair, private key will email to your.`,
          btnCreate: 'Create'
        },
        actionDelete: {
          title: 'Delete SSH Key',
          sure: 'Delete this SSH Key?',
          btn: 'Delete',
          successNotify: 'Delete SSH key successfully'
        }
      },
      billing: {
        title: 'Billing',
        balance: 'Balance',
        unpaidAmount: 'Amount you owe',
        additionalAmount: 'Top up amount',
        topup: {
          withBalance: 'Make Payment',
          withCouponCode: 'Coupon Code',
          useVND: 'Use VND',
          useUSD: 'Use USD',
          selectAmount: 'Select amount',
          inputAmount: 'Or enter your amount'
        },
        payIn: {
          title: 'Top up',
          moneyType: 'Currency',
          exchangeRate: 'Exchange rate',
          selectDenominationsTitle: 'Choose your amount',
          titleInputDenominations: 'Or enter Top up amount',
          descDenominations: 'Your balance will be increase',
          btn: 'Make payment'
        },
        promotionCode: {
          title: 'Coupon',
          desc: 'Enter your counpon code.'
        },
        invoices: {
          title: 'Invoices',
          id: 'Invoice ID',
          status: 'Status',
          amount: 'Amount',
          created: 'Created at',
          expired: 'Duedate',
          action: 'Action',
          btnPayment: 'Charge',
          btnShowInvoice: 'View & download',
          statusInvoice: {
            openLabel: 'Open',
            paidLabel: 'Paid',
            cancelledLabel: 'Cancelled',
            undefinedLabel: 'Undefined',
            allLabel: 'All'
          },
          showInvoice: 'Detail',
          values : "Resources",
        },
        paymentSuccess: {
          title: 'Your payment is successful',
          titleInfo: 'Information',
          paymentMethod: 'Payment Gateway',
          price: 'Amount',
          balance: 'Balance'
        },
        paymentFailed: {
          title: 'Payment Failed.'
        },
        paymentHistories: {
          title: 'Payment history',
          labelId: 'Transaction ID',
          labelMethod: 'Method',
          labelCreated: 'Date',
          labelAmount: 'Amount',
          btnInvoiceDetail: 'Receipt'
        },
        dialogConfirmPayment: {
          title: 'Confirm Payment',
          labelContent: 'Payment Info',
          content: 'Top up balance',
          labelAmount: 'Amoun',
          methods: {
            title: 'Method',
            onepay: {
              title: 'Make a OnePay payment, amount',
              content: `OnePay accept: ATM with Internet Banking, Visa/Master/JCB... card. Please, keep your browser is always open while transaction is in progress`,
              btn: 'Continue to OnePay'
            },
            paypal: {
              title: 'Make a PayPal payment, amount',
              content: 'It may take a few minutes to process your payment with PayPal.',
              btn: 'Continue to PayPal'
            },
            transfer: {
              label: 'Bank transfer',
              content: `Bank information:
                        <br>
                        Account name: Công Ty Cổ Phần Công Nghệ Và Giải Pháp Trực Tuyến IDC
                        <br>
                        Account number: 22010005201962
                        <br>
                        Bank's name: Ngân hàng Thương mại cổ phần Đầu tư và Phát triển Việt Nam (BIDV)
                        <br>
                        SWIFT code: BIDVVNVX
                        <br>`,
              labelAmount: 'Amount',
              smsContentPayIn: 'Purpose: Nap tien dich vu',
              smsContentPayment: 'Purpose: Check out order'
            }
          }
        }
      },
      confirmOtp: {
        mess: 'A verification code has been sent to your email address!',
        placeholder: 'Enter Auth Code'
      },
      plan: {
        title: 'Product pricing',
        longTermPaymentChoose: 'Choose a payment term',
        longTermPayment: 'Payment term',
        buyBtn: 'Buy now'
      },
      agency: {
        title: 'Agency',
        user: 'Accounts',
        user_create: 'Create new Account',
        invoice: 'Invoices',
        account: 'Account',
        report: {
          title: 'Report',
          overview: 'Overview'
        }
      }
    }
  }
})
