import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import router from './router'
import { i18n } from './plugins/i18n'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
// import './plugins/click-away'

import './scss/app.scss';

require('moment/locale/vi')
require('moment-timezone')
Vue.use(VueMoment, { moment })

Vue.use(Antd);
Vue.use(VueLodash, { lodash: lodash })

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')