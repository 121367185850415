import Axios from 'axios'
import {getAccessToken, removeAccessToken} from '@/plugins/storage'
import {URI as AUTH_URI} from '@/services/auth/login'
import { notification } from 'ant-design-vue';

const instance = Axios.create({
	baseURL: process.env.VUE_APP_BACKEND_URL,
	headers: {     
		'Content-Type': 'application/json;charset=UTF-8',
	},
    withCredentials: true,
});

instance.interceptors.request.use(function (config) {
	config.headers.Authorization = "Bearer "+ getAccessToken();
    return config;
});

instance.interceptors.response.use(response=> {
	return response.data;
}, (e)=> {

	if (AUTH_URI === e.config.url) {
		return null
	}
    // Nếu token hết hạn thì chuyển về trang login
	else if(e.response.status == 401) {
		// Thực hiện redirect
		removeAccessToken();
		window.location.href	=	'/sign-in';
	} else {
		notification.error({
			message: 'Internal Server Error'
		});
	}
});

export default instance;