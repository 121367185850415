// If you are using a modular system then import the Vue2Storage class
import Vue2Storage from 'vue2-storage';

// You can specify the storage configuration when instantiating the class
export const storage = new Vue2Storage({
  prefix: 'app_',
  driver: 'local',
  ttl: 60 * 60 * 24 * 1000, // 24h
  replacer: (key, value) => value
});

export const setAccessToken = (value) => {
	storage.setOptions({
	    ttl: 60 * 60 * 24 * 1000,
	});
	storage.set('access_token', value);

};
export const getAccessToken = () => {
	return storage.get('access_token');
};
export const removeAccessToken = () => {
	storage.remove('access_token');
};

export const setUserInfo = (value) => {
	storage.set('user', JSON.stringify(value));
};
export const getUserInfo = () => {
	let value = storage.get('user');
	if(value) value = JSON.parse(value) 
	return value;
};

export const setLang = (value) => {
	storage.set('lang', value);
};
export const getLang = () => {
	return storage.get('lang');
};